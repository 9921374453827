<template>
	<div class="row">
		<div class="col-md-12">
			<div class="container-fluid">
				<div class="row" style="height: 50px;">
					<div class="col-md-12 col-lg-4 my-auto sba-header-text">
						<img height="25" width="43" class="org-logo" />
						&nbsp;
						<span class="text-muted menu-title-text">TAXI Selfbilling APP</span>

						<i class="fas fa-bars sba-menu-toggle float-right m-2 mobile-shown" aria-hidden="true" @click="showMobileMenu = !showMobileMenu"></i> 
					</div>

					<div class="my-auto mobile-hidden text-right sba-header-login col-lg-8" v-if="currentUser">
						<div class="dropdown d-inline" v-if="currentUser.rights.organization.doswitch">
							<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="toggleOrganizationDropdown()">
								Switch organization
							</button>
							<div id="organization-dropdown" class="dropdown-menu mt-2" aria-labelledby="dropdownMenuButton">
								<a v-for="organization in organizations" :key="organization.id" class="dropdown-item text-dark" @click="switchOrganization(organization)">{{organization.name}}</a>
							</div>
						</div>

						<button type="button" class="btn btn-primary d-inline ml-3" style="cursor: inherit;"><i class="fas fa-user" aria-hidden="true"></i> {{ currentUser.name }} </button>
						<button type="button" class="btn btn-danger d-inline ml-3" v-on:click="doLogout()"><i class="fas fa-right-from-bracket" aria-hidden="true"></i> Logout</button>
					</div>
				</div>
				<div class="row bg-supplier mb-3" v-if="showMobileMenu">
					<div class="col-md-12">
						Menu
						<div class="list-group mb-3">
						<select v-if="currentUser.rights.organization.doswitch" @change="switchOrganization()" v-model="selectedOrganization" class="form-control text-left ">
							<option :value='0' selected>Switch organization</option>
							<option v-for="organization in organizations" :key="organization.id" :value='organization' selected>{{organization.name}}</option>
						</select>
						<a class="list-group-item list-group-item-action" v-for="menuItem in menuItems" :key="menuItem.id" v-on:click="switchMenu(menuItem); showMobileMenu = false" :class="menuItem.id == currentMenu.id ? 'active text-white': ''">
							{{ menuItem.label }}
						</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12 mobile-hidden">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="tiles">
							<a v-for="menuItem in menuItems" :key="menuItem.id" :class="'tile double ' + menuItem.class" v-on:click="switchMenu(menuItem)">
								<div class="tile-body">
									<i class="fa" :class="menuItem.icon" aria-hidden="true"></i>
								</div>
								<div class="tile-object">
									<div class="name"> {{ menuItem.label }} </div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="row" v-if="currentMenu && !customHeader">
					<div class="col-md-12">
						<div class="page-title">
							<h1><i class="fa" :class="currentMenu.icon" aria-hidden="true"></i> {{ currentMenu.label }}</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { MenuIcon, MobileMenuIcon } from '@/components/Svg'

import notifications from '@/data/notifications'
import {
	searchPath,
	menuHiddenBreakpoint,
	localeOptions,
	countryOptions,
	buyUrl,
	defaultColor
} from '@/constants/config'
import { getDirection, setDirection } from '@/utils'
export default {

	data () {
		return {
			menuItems: [],
			user: {},
			currentMenu: {},
			customHeader: false,
			showMobileMenu: false,
			organizations : [],
			selectedOrganization : 0,
		}
	},
	methods: {
		...mapActions(['setLang', 'logout']),
		handleDocumentforMobileSearch () {
			if (!this.isSearchOver) {
				this.isMobileSearch = false;
				this.searchKeyword = '';
			}
		},

		changeLocale (locale, direction) {
			const currentDirection = getDirection().direction;
			if (direction !== currentDirection) {
				setDirection(direction);
			}

			this.setLang(locale);
		},
		
		switchMenu (menuItem) {
			this.currentMenu = menuItem;
			
			if (menuItem.name == 'logout') {
				this.doLogout();
				return;
			}
			
			this.$router.push({ name: menuItem.name });
		},
		
		toggleOrganizationDropdown () {
			var dropdownDiv = document.getElementById("organization-dropdown");
			
			//check if has class already
			if (dropdownDiv.classList.contains('show')) {
				dropdownDiv.classList.remove("show");
			} else {
				dropdownDiv.classList.add("show");
			}
		},

		changeCountry (country) {
			this.setCountry(country);
		},
		
		doLogout () {
			this.logout().then(() => {
				this.$router.push('/page/login');
			}, (error) => {
				this.$router.push('/page/login');
			});
		},

		toggleFullScreen () {
			const isInFullScreen = this.isInFullScreen();

			var docElm = document.documentElement;
			if (!isInFullScreen) {
				if (docElm.requestFullscreen) {
					docElm.requestFullscreen();
				} else if (docElm.mozRequestFullScreen) {
					docElm.mozRequestFullScreen();
				} else if (docElm.webkitRequestFullScreen) {
					docElm.webkitRequestFullScreen();
				} else if (docElm.msRequestFullscreen) {
					docElm.msRequestFullscreen();
				}
			} else {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			}
			this.fullScreen = !isInFullScreen;
		},
		getThemeColor () {
			return localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : defaultColor;
		},
		isInFullScreen () {
			return (
				(document.fullscreenElement && document.fullscreenElement !== null) ||
				(document.webkitFullscreenElement &&
				document.webkitFullscreenElement !== null) ||
				(document.mozFullScreenElement &&
				document.mozFullScreenElement !== null) ||
				(document.msFullscreenElement && document.msFullscreenElement !== null)
			)
		},
		switchOrganization(organization) {
			//set selected organization
			this.selectedOrganization = organization;

			//do switch
			this.req('GET', '/organization/doswitch/'+ this.selectedOrganization.id).then((response) => {
				if (response.status) {
				//use api url for matching subdomain
					let url = process.env.VUE_APP_API_URL;
					if (!url) {
						url = window.location.href;
					}
					let match = [];
					//returns true if the url has a word character with -
					//Including the . at the end
					//eslint-disable-next-line
					if (url.match('(.*://)(([\Da-z-]*)\.)')) {
						var numberCheck = /\d/;  
						//if match contains a digit only select http://
						//eslint-disable-next-line
						match = url.match('(.*://)(([\Da-z-]*)\.)');
						if(match[2] && numberCheck.test(match[2])) {
							match = url.match('(.*://)');
						}
					}
					
					if (match) {
						//Match 0 has the current subdomain with a dot
						//So the code bellow selects subdomain, and replaces it with new subdomain
						//if site has a subdomain add it to href
						if (response.data && response.data.organization && response.data.organization.subdomain_name !== null) {
							url = url.replace(match[0], match[1] + response.data.organization.subdomain_name + '.');
							url = url.replace('page/user/login', '');
						} else {
							url = url.replace(match[0], match[1]);
							url = url.replace('page/user/login', '');
						}
					}

					window.location.href = url;
				}
			});	
		},
		getOrganizations() {
			this.req('GET', '/organization/getForSwitch').then((response) => {
				if (response.status) {
					this.organizations = response.data;
				}
			});		
		},
		sortArrayByOrder (unsorted) {
			let sorted = unsorted.sort((a,b) => {
				return a.order - b.order
			})
			return sorted;
		},
		isCustomHeader(to) {
			this.customHeader = false;
			if (to.name == 'invoice' || to.name == 'logbook') {
				this.customHeader = true;
			}
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
			currentCountry: 'currentCountry',
			menuType: 'getMenuType',
			menuClickCount: 'getMenuClickCount'
		})
	},
	beforeUnmount () {
		document.removeEventListener('click', this.handleDocumentforMobileSearch);
	},
	created () {
		this.getOrganizations();
		const color = this.getThemeColor();
		this.isDarkActive = color.indexOf('dark') > -1;
		//get menu
		this.req('POST', '/menu/get').then((response) => {
			if (response.status) {
				if (Array.isArray(response.data)) {
					this.menuItems = this.sortArrayByOrder(response.data);
					if (this.menuItems[0]) {
						this.currentMenu = this.menuItems[0];
					}
				}
			}
		});
		
		this.isCustomHeader(this.$router.currentRoute.value);
	},
	watch: {
		$route (to, from){
			this.isCustomHeader(to);
		},
		'$i18n.locale' (to, from) {
			if (from !== to) {
				this.$router.go(this.$route.path);
			}
		},
		isDarkActive (val) {
			let color = this.getThemeColor();
			let isChange = false;
			if (val && color.indexOf('light') > -1) {
				isChange = true;
				color = color.replace('light', 'dark');
			} else if (!val && color.indexOf('dark') > -1) {
				isChange = true;
				color = color.replace('dark', 'light');
			}
			if (isChange) {
				localStorage.setItem('themeColor', color);
				setTimeout(() => {
					window.location.reload();
				}, 500);
			}
		},
		isMobileSearch (val) {
			if (val) {
				document.addEventListener('click', this.handleDocumentforMobileSearch);
			} else {
				document.removeEventListener('click', this.handleDocumentforMobileSearch);
			}
		}
	}
}
</script>
