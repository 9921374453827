<template>
	<div id="app-container" :class="getMenuType">
		<div class="sbaMain">
			<top-nav/>
			<main>
				<div class="container-fluid">
					<router-view/>
				</div>
			</main>
		</div>
	</div>
</template>
<script>
import TopNav from '@/containers/TopNav'
import { mapGetters, mapActions } from 'vuex'

export default {
	data () {
		return { show: false }
	},
	components: {
		TopNav,
	},
	computed: {
		...mapGetters(['getMenuType'])
	},
	methods: {
		...mapActions(['check']),
	},
	created () {
		this.check();
	}
}
</script>
